<ion-app>
  <!-- <ion-split-pane contentId="main">
    <ion-menu contentId="main" [disabled]="!(isLoggedInStream | async)">
      <ion-header>
        <ion-toolbar>
          <ion-title>Menu</ion-title>
        </ion-toolbar>
      </ion-header>

      <ion-content>
        <div id="container">
          <div class="tabcontent">
            <mat-card>
              <mat-list role="list">
                <mat-list-item role="listitem" class="menutitle">
                  Navigation</mat-list-item
                >
                <mat-list-item
                  role="listitem"
                  routerLink="/frontend-dashboard"
                  class="menuitem"
                  routerLinkActive="active-link"
                  [routerLinkActiveOptions]="{ exact: true }"
                  
                  ><mat-icon
                    aria-hidden="false"
                    aria-label="Example home icon"
                    class="menuicon"
                    >dashboard</mat-icon
                  >
                  Dashboard
                </mat-list-item>

                <mat-list-item role="listitem" class="menutitle">
                  E Warranty</mat-list-item
                >

                <mat-list-item
                  role="listitem"
                  routerLink="/frontend-ewarranty-enter-serial"
                  class="menuitem"
                  routerLinkActive="active-link"
                  [routerLinkActiveOptions]="{ exact: true }"
                  ><mat-icon
                    aria-hidden="false"
                    aria-label="Example home icon"
                    class="menuicon"
                    >arrow_right_alt</mat-icon
                  >
                  Enter Serial
                </mat-list-item>
                <mat-list-item
                  role="listitem"
                  routerLink="/frontend-ewarranty-product-info"
                  class="menuitem"
                  routerLinkActive="active-link"
                  [routerLinkActiveOptions]="{ exact: true }"
                  ><mat-icon
                    aria-hidden="false"
                    aria-label="Example home icon"
                    class="menuicon"
                    >arrow_right_alt</mat-icon
                  >
                  Product Info
                </mat-list-item>
                <mat-list-item
                  role="listitem"
                  routerLink="/frontend-ewarranty-user-info"
                  class="menuitem"
                  routerLinkActive="active-link"
                  [routerLinkActiveOptions]="{ exact: true }"
                  ><mat-icon
                    aria-hidden="false"
                    aria-label="Example home icon"
                    class="menuicon"
                    >arrow_right_alt</mat-icon
                  >
                  User Info
                </mat-list-item>
                <mat-list-item
                  role="listitem"
                  routerLink="/frontend-ewarranty-verify-email"
                  class="menuitem"
                  routerLinkActive="active-link"
                  [routerLinkActiveOptions]="{ exact: true }"
                  ><mat-icon
                    aria-hidden="false"
                    aria-label="Example home icon"
                    class="menuicon"
                    >arrow_right_alt</mat-icon
                  >
                  Email Verification
                </mat-list-item>
                <mat-list-item
                  role="listitem"
                  routerLink="/warranty-activation-status"
                  class="menuitem"
                  routerLinkActive="active-link"
                  [routerLinkActiveOptions]="{ exact: true }"
                  ><mat-icon
                    aria-hidden="false"
                    aria-label="Example home icon"
                    class="menuicon"
                    >arrow_right_alt</mat-icon
                  >
                  Warraty Activated
                </mat-list-item>

                <mat-list-item role="listitem" class="menutitle">
                  E Claim</mat-list-item
                >

                <mat-list-item
                  role="listitem"
                  routerLink="/frontend-eclaim-enter-serial"
                  class="menuitem"
                  routerLinkActive="active-link"
                  [routerLinkActiveOptions]="{ exact: true }"
                  ><mat-icon
                    aria-hidden="false"
                    aria-label="Example home icon"
                    class="menuicon"
                    >arrow_right_alt</mat-icon
                  >
                  Enter Serial
                </mat-list-item>
                <mat-list-item
                  role="listitem"
                  routerLink="/frontend-eclaim-product-info"
                  class="menuitem"
                  routerLinkActive="active-link"
                  [routerLinkActiveOptions]="{ exact: true }"
                  ><mat-icon
                    aria-hidden="false"
                    aria-label="Example home icon"
                    class="menuicon"
                    >arrow_right_alt</mat-icon
                  >
                  Product Info
                </mat-list-item>
                <mat-list-item
                  role="listitem"
                  routerLink="/frontend-eclaim-verify-email"
                  class="menuitem"
                  routerLinkActive="active-link"
                  [routerLinkActiveOptions]="{ exact: true }"
                  ><mat-icon
                    aria-hidden="false"
                    aria-label="Example home icon"
                    class="menuicon"
                    >arrow_right_alt</mat-icon
                  >
                  Email Verification
                </mat-list-item>
                <mat-list-item
                  role="listitem"
                  routerLink="/warranty-claim-status"
                  class="menuitem"
                  routerLinkActive="active-link"
                  [routerLinkActiveOptions]="{ exact: true }"
                  ><mat-icon
                    aria-hidden="false"
                    aria-label="Example home icon"
                    class="menuicon"
                    >arrow_right_alt</mat-icon
                  >
                  Claim Activated
                </mat-list-item>

                <mat-list-item role="listitem" class="menutitle">
                  Check Warranty</mat-list-item
                >

                <mat-list-item
                  role="listitem"
                  routerLink="/frontend-product-warranty-enter-serial"
                  class="menuitem"
                  routerLinkActive="active-link"
                  [routerLinkActiveOptions]="{ exact: true }"
                  ><mat-icon
                    aria-hidden="false"
                    aria-label="Example home icon"
                    class="menuicon"
                    >arrow_right_alt</mat-icon
                  >
                  Enter Serial
                </mat-list-item>
                <mat-list-item
                  role="listitem"
                  routerLink="/frontend-product-warranty-validity"
                  class="menuitem"
                  routerLinkActive="active-link"
                  [routerLinkActiveOptions]="{ exact: true }"
                  ><mat-icon
                    aria-hidden="false"
                    aria-label="Example home icon"
                    class="menuicon"
                    >arrow_right_alt</mat-icon
                  >
                  Warranty Validity
                </mat-list-item>
                <mat-list-item role="listitem" class="menutitle">
                  Check Claim Status</mat-list-item
                >
                <mat-list-item
                role="listitem"
                routerLink="/frontend-enter-claim-number"
                class="menuitem"
                routerLinkActive="active-link"
                  [routerLinkActiveOptions]="{ exact: true }"
                ><mat-icon
                  aria-hidden="false"
                  aria-label="Example home icon"
                  class="menuicon"
                  >arrow_right_alt</mat-icon
                >
                Enter Claim Number
              </mat-list-item>
              <mat-list-item
              role="listitem"
              routerLink="/frontend-claim-status"
              class="menuitem"
              routerLinkActive="active-link"
                  [routerLinkActiveOptions]="{ exact: true }"
              ><mat-icon
                aria-hidden="false"
                aria-label="Example home icon"
                class="menuicon"
                >arrow_right_alt</mat-icon
              >
              Claim Status
            </mat-list-item>
              </mat-list>
            </mat-card>
          </div>
        </div>
      </ion-content>
    </ion-menu>

    <ion-router-outlet id="main"></ion-router-outlet>
  </ion-split-pane> -->
  <ion-router-outlet></ion-router-outlet>
</ion-app>
