import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () =>
      import('./home/home.module').then((m) => m.HomePageModule),
  },
  // {
  //   path: '',
  //   redirectTo: 'home',
  //   pathMatch: 'full'
  // },

  {
    path: '',
    loadChildren: () =>
      import('./frontend-dashboard/frontend-dashboard.module').then(
        (m) => m.FrontendDashboardPageModule
      ),
  },
  {
    path: 'frontend-dashboard',
    loadChildren: () =>
      import('./frontend-dashboard/frontend-dashboard.module').then(
        (m) => m.FrontendDashboardPageModule
      ),
  },
  {
    path: 'frontend-ewarranty-enter-serial',
    loadChildren: () =>
      import(
        './frontend-ewarranty-enter-serial/frontend-ewarranty-enter-serial.module'
      ).then((m) => m.FrontendEwarrantyEnterSerialPageModule),
  },
  {
    path: 'frontend-ewarranty-product-info',
    loadChildren: () =>
      import(
        './frontend-ewarranty-product-info/frontend-ewarranty-product-info.module'
      ).then((m) => m.FrontendEwarrantyProductInfoPageModule),
  },
  {
    path: 'frontend-ewarranty-user-info',
    loadChildren: () =>
      import(
        './frontend-ewarranty-user-info/frontend-ewarranty-user-info.module'
      ).then((m) => m.FrontendEwarrantyUserInfoPageModule),
  },
  {
    path: 'frontend-ewarranty-verify-email',
    loadChildren: () =>
      import(
        './frontend-ewarranty-verify-email/frontend-ewarranty-verify-email.module'
      ).then((m) => m.FrontendEwarrantyVerifyEmailPageModule),
  },
  {
    path: 'warranty-activation-status',
    loadChildren: () =>
      import(
        './warranty-activation-status/warranty-activation-status.module'
      ).then((m) => m.WarrantyActivationStatusPageModule),
  },
  {
    path: 'frontend-eclaim-enter-serial',
    loadChildren: () =>
      import(
        './frontend-eclaim-enter-serial/frontend-eclaim-enter-serial.module'
      ).then((m) => m.FrontendEclaimEnterSerialPageModule),
  },
  {
    path: 'frontend-eclaim-product-info',
    loadChildren: () =>
      import(
        './frontend-eclaim-product-info/frontend-eclaim-product-info.module'
      ).then((m) => m.FrontendEclaimProductInfoPageModule),
  },
  {
    path: 'frontend-eclaim-user-info',
    loadChildren: () =>
      import(
        './frontend-eclaim-user-info/frontend-eclaim-user-info.module'
      ).then((m) => m.FrontendEclaimUserInfoPageModule),
  },
  {
    path: 'frontend-eclaim-verify-email',
    loadChildren: () =>
      import(
        './frontend-eclaim-verify-email/frontend-eclaim-verify-email.module'
      ).then((m) => m.FrontendEclaimVerifyEmailPageModule),
  },
  {
    path: 'warranty-claim-status',
    loadChildren: () =>
      import(
        './warranty-claim-status/warranty-claim-status.module'
      ).then((m) => m.WarrantyClaimStatusPageModule),
  },
  {
    path: 'frontend-product-warranty-enter-serial',
    loadChildren: () =>
      import(
        './frontend-product-warranty-enter-serial/frontend-product-warranty-enter-serial.module'
      ).then((m) => m.FrontendProductWarrantyEnterSerialPageModule),
  },
  {
    path: 'frontend-product-warranty-validity',
    loadChildren: () =>
      import(
        './frontend-product-warranty-validity/frontend-product-warranty-validity.module'
      ).then((m) => m.FrontendProductWarrantyValidityPageModule),
  },
  {
    path: 'frontend-claim-status',
    loadChildren: () =>
      import('./frontend-claim-status/frontend-claim-status.module').then(
        (m) => m.FrontendClaimStatusPageModule
      ),
  },
  {
    path: 'frontend-enter-claim-number',
    loadChildren: () =>
      import(
        './frontend-enter-claim-number/frontend-enter-claim-number.module'
      ).then((m) => m.FrontendEnterClaimNumberPageModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
